import React from "react";
import { useHistory, useParams } from "react-router";
import { SiteFooter } from "./components/footer";
import { SiteHeader } from "./components/header";
import { Fade } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";

export const Blog_Single = () => {
  //  const [posts, setPosts] = useState({info: []})

  const params = useParams();
  const it_number = params.id;
  const that_number = params.test;

  const [player, setPlayer] = React.useState([]);
  const [playerSingleFetch, setPlayerSingleFetch] = React.useState([]);
  const fetchDataPlayer = () => {
    fetch("https://cdn.lk-ft.ru/footballers")
      .then((response) => response.json())
      .then((data) => {
        setPlayer(data);
      });
    fetch(
      `https://cdn.lk-ft.ru/footballers?f_email=${localStorage.username}&f_password=${localStorage.password}`
    )
      .then((response) => response.json())
      .then((data) => {
        setPlayerSingleFetch(data);
      });
  };
  React.useEffect(() => {
    fetchDataPlayer();
  }, []);

  // console.log(player);

  const [posts, setPosts] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [is_html, setIs_html] = React.useState(false);
  const [isError, setIsError] = React.useState(false);
  const fetchData = () => {
    fetch("https://cdn.lk-ft.ru/posts")
      .then((response) => response.json())
      .then((data) => {
        setIsLoading(false);
        setPosts(data);
      })
      .catch((error) => {
        setIsLoading(false);
        setIsError(true);
        console.log(error);
      });
  };


  React.useEffect(() => {
    fetchData();
  }, []);


  if (isLoading) {
    return <div>Загрузка...</div>;
  }

  function wrapLinksInATags(text) {
    let urlPattern = /(https?:\/\/[^\s]+)/g;
    if (urlPattern.test(text)) {
        return {text: text.replace(urlPattern, '<a href="$1" target="_blank">$1</a>'), html: true}
    }
    return {text: text, html: false};
  }

  var post_img = [];
  var post_rich_text;
  var post_title_text;
  var post_author;
  var post_author_lastname;
  var post_teaser;
  var post_date;
  var post_author_lastname;
  let post_video_url = "";
  let full_url = "";
  posts.map((e, i) => {
    if (e.id == it_number) {

      let result = wrapLinksInATags(e.Post_text)
      post_img = e.Post_image;
      post_rich_text = result.text;
      post_title_text = e.Post_Title;
      post_author = e.Post_author;
      post_author_lastname = e.Post_author_lastname;
      post_teaser = e.Post_teaser;
      post_date = e.Post_Date.split("-").reverse().join(".");
      if (e.Post_video) {
        console.log(e.Post_video);
        post_video_url = e.Post_video;
        full_url = post_video_url
        // let start = post_video_url.indexOf("/") + 1;
        // let end = post_video_url.indexOf("?");
        
        let start = post_video_url.indexOf("be/") + 3;
        console.log(start);
        let end = post_video_url.indexOf("?");
        if (end === -1) { // Если нет "?", используем длину строки как конец
          end = post_video_url.length;
        }
        // let extractedString = url.substring(start, end);

        post_video_url = post_video_url.substring(start, end);
        console.log(post_video_url);
      }

    }
  });
  // React.useEffect(() => {
  //   // console.log("hui", post_rich_text)
  // }, [])
 
  // var isHtml = false
  // post_rich_text, isHtml = wrapLinksInATags(post_rich_text);
  // let result = wrapLinksInATags(post_rich_text);
  //     if (result.html) {
  //       setIs_html(true)
  //       post_rich_text =result.text
  // }
  return (
    <>
      <SiteHeader />
      <section style={{ minHeight: "100vh" }}>
        <div class="container" style={{ margin: "0px auto" }}>
          <div class="blog-title">
            <span>{post_title_text}</span>
          </div>
          <div class="single-blog-date">
            <span>{post_date}</span>
            <span>
              {post_author} {post_author_lastname}
            </span>
          </div>
          <div class="single-blog-img-container">
            {post_img.length <= 1 ? (
              <img
                class="single-blog-img"
                src={"https://cdn.lk-ft.ru" + post_img[0]?.url}
                alt="blog-img"
              ></img>
            ) : (
              <div className="slide-container">
                <Fade>
                  {post_img.map((slideImage, index) => (
                    <div className="each-fade" key={index}>
                      {/* <div style={{ 'backgroundImage': `url(${"https://cdn.lk-ft.ru" + slideImage?.url})` }}>
                                            <span>{slideImage.caption}</span>
                                        </div> */}
                      <div className="image-container">
                        <img
                          class="single-blog-img"
                          src={"https://cdn.lk-ft.ru" + slideImage?.url}
                          alt="blog-img"
                        ></img>
                      </div>
                    </div>
                  ))}
                </Fade>
              </div>
            )}
            {post_video_url && (
              <>
                <iframe
                  width="560"
                  height="315"
                  src={"https://www.youtube.com/embed/" + post_video_url + "?si=68cCtfO2yU7ylj5v"}
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>

              </>
            )}
          </div>

          <div class="single-blog-content">
            {
              full_url && (
                <><a href={full_url}>Посмотреть по ссылке</a><br /></>
              )
            }
            {
              post_rich_text && post_rich_text.includes("href") ? (<div
                id="post-content"
                dangerouslySetInnerHTML={{ __html: post_rich_text }}
              ></div>)
                : <span>{post_rich_text}</span>
            }
          </div>
        </div>
      </section>
      <SiteFooter />
    </>
  );
};
