


export const BLOG_ROUTE = '/blog'
export const BLOG_SINGLE_ROUTE= '/blog-single'
export const AWARD_ROUTE = '/award'
export const CONTACT_ROUTE = '/contact'
export const HOMEPAGE_ROUTE = '/home'
export const AUTH_ROUTE = '/auth'
export const REGISTRATION_ROUTE = '/registration'
export const ADMIN_ROUTE = '/admin?panel'
