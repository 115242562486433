import React, { useState } from "react";
import { Chart } from "react-google-charts";
import { useStatistics } from "./components/hooks/useStat";
import './switcher.css'
import TestInfo from "./TestInfo";
import { CustomChart } from "./components/customChart";

export const Stats = ({ stats }) => {
    const { get, set } = useStatistics();
    const [show, setShow] = useState(false);
    const [speedSwitcher, setSpeedSwitcher] = useState(false);
    const [speed_s_razbegaSwitcher, setSpeed_s_razbegaSwitcher] = useState(false);
    const [footSkillSwitcher, setFootSkillSwitcher] = useState(false);
    const [dribblingSwitcher, setDribblingSwitcher] = useState(false);
    const [dribblingSwitcher2, setDribblingSwitcher2] = useState(false);
    const [showInfo, setShowInfo] = useState(false);


    const handleChangeSpeed = () => {
        setSpeedSwitcher(!speedSwitcher);
        // console.log(speedSwitcher)
    }
    const handleChangeSpeed_s_razbega = () => {
        setSpeed_s_razbegaSwitcher(!speed_s_razbegaSwitcher);
        // console.log(speedSwitcher)
    }
    const handleChangeFootSkill = () => {
        setFootSkillSwitcher(!footSkillSwitcher)
    }

    const handleChangeDribbling = () => {
        setDribblingSwitcher(!dribblingSwitcher)
    }

    const handleChangeDribbling2 = () => {
        setDribblingSwitcher2(!dribblingSwitcher2)
    }

    let showInfoFunction = () => {
        setShowInfo(!showInfo)
    }

    React.useEffect(() => {
        if (typeof stats == "object" && typeof stats[0] != "number") {
            set(stats);
        }
        return () => { };
    }, [stats]);
    console.log("hhh", get()?.MessiTestDriblingTest2);
    console.log("hhj", get()?.FootSkillList)
    if (show) {
        return (
            <div className="container">
                <section className="section-header-2">
                    <div className="section-flex">
                        <h2 class="title" onClick={() => setShow(false)}>
                            <span class="text-theme-show">Моя статистика</span>
                            <img src="/images/client/down-arrow.svg" alt="right-arrow" className="client-menu-arrow arrow-margin-bottom"></img>
                        </h2>
                        <div className="test-info" onClick={showInfoFunction}>Подробнее о тестировании</div>
                    </div>

                    <div className="my-stats-chart">
                        <div>
                            <span className="my-stats-label">Сила удара</span>
                            <CustomChart data={get()?.HitList} />
                        </div>
                        <div>
                            <div className="chart-with-switcher">
                                <span className="my-stats-label">10 метров с места {speedSwitcher ? '(км/ч)' : '(сек)'}</span>
                                <label class="switch">
                                    <input type="checkbox" checked={!speedSwitcher} onChange={handleChangeSpeed} />
                                    <span class="slider round"></span>
                                </label>
                            </div>
                            {
                                (speedSwitcher) ?
                                    <CustomChart data={get()?.SpeedList} />
                                    :
                                    <CustomChart data={get()?.SpeedList2} /> 
                            }


                        </div>
                        <div>
                            <div className="chart-with-switcher">
                                <span className="my-stats-label">10 метров с разбега {speed_s_razbegaSwitcher ? '(км/ч)' : '(сек)'}</span>
                                <label class="switch">
                                    <input type="checkbox" checked={!speed_s_razbegaSwitcher} onChange={handleChangeSpeed_s_razbega} />
                                    <span class="slider round"></span>
                                </label>
                            </div>
                            {
                                speed_s_razbegaSwitcher ?
                                    <CustomChart data={get()?.SpeedList_s_razbegu} /> 
                                    :
                                    <CustomChart data={get()?.SpeedList_s_razbegu2} /> 
                            }

                        </div>
                        <div>
                            <span className="my-stats-label">Прыжок в высоту</span>
                            <CustomChart data={get()?.JumpList} /> 
                        </div>
                        <div>
                            <span className="my-stats-label">Прыжок в длинну</span>
                            <CustomChart data={get()?.JumpList2} /> 
                        </div>
                        <div>
                            <span className="my-stats-label">Скорость реакции</span>
                            <CustomChart data={get()?.ReactionList} /> 
                        </div>
                        <div>
                            <span className="my-stats-label">Agility Test</span>
                            <CustomChart data={get()?.AgilityList} /> 
                        </div>
                        <div>
                            <div className="chart-with-switcher">
                                <span className="my-stats-label">FootSkill Test {footSkillSwitcher ? "(сек)" : '(удары)'}</span>
                                <label class="switch">
                                    <input type="checkbox" checked={!footSkillSwitcher} onChange={handleChangeFootSkill} />
                                    <span class="slider round"></span>
                                </label>
                            </div>
                            {
                                footSkillSwitcher ?
                                    <CustomChart data={get()?.FootSkillList} /> 
                                    :
                                    <CustomChart data={get()?.FootSkillList_hits} /> 
                            }

                        </div>
                        <div>
                            <span class="my-stats-label">Точность</span>
                            <CustomChart data={get()?.SharpshootingList} /> 
                        </div>
                        <div>
                            <div className="chart-with-switcher">
                                <span className="my-stats-label">Дриблинг тест {dribblingSwitcher ? "(сек)" : '(касания)'}</span>
                                <label class="switch">
                                    <input type="checkbox" checked={!dribblingSwitcher} onChange={handleChangeDribbling} />
                                    <span class="slider round"></span>
                                </label>
                            </div>
                            {
                                dribblingSwitcher ?
                                    <CustomChart data={get()?.MessiTestDriblingTest} /> 
                                    :
                                    <CustomChart data={get()?.ExstraColorDriblingTest} /> 
                            }
                        </div>
                        <div>
                            <div className="chart-with-switcher">
                                <span className="my-stats-label">Дриблинг тест 2 {dribblingSwitcher2 ? "(сек)" : '(касания)'}</span>
                                <label class="switch">
                                    <input type="checkbox" checked={!dribblingSwitcher2} onChange={handleChangeDribbling2} />
                                    <span class="slider round"></span>
                                </label>
                            </div>
                            {
                                dribblingSwitcher2 ?
                                    <CustomChart data={get()?.MessiTestDriblingTest2} /> 
                                    :
                                    <CustomChart data={get()?.ExstraColorDriblingTest2} /> 
                            }
                        </div>
                    </div>
                </section>
                {
                    showInfo ? <TestInfo togglePopUp={showInfoFunction} /> : null
                }
            </div>
        );
    } else {
        return (
            <div className="container">
                <section className="section-header-2 section-flex">
                    <h2 class="title" onClick={() => setShow(true)} style={{ cursor: "pointer" }}>
                        <span class="text-theme">Моя статистика</span>
                        <img src="/images/client/right-arrow.svg" alt="right-arrow" className="client-menu-arrow arrow-margin-bottom " ></img>
                    </h2>
                    <div className="test-info" onClick={showInfoFunction}>Подробнее о тестировании</div>
                </section>
                {
                    showInfo ? <TestInfo togglePopUp={showInfoFunction} /> : null
                }
            </div>
        );
    }
};
